/**
 * Exports are all mocked for integration tests. See:
 * https://github.com/hex-inc/hex/blob/ff020357bc8bc5ad0aa7b229768b808dafe2c292/packages/integration-tests/jest.wrapper.ts#L70-L84
 *
 * These "document.head.dataset.*" attributes come from `packages/server/src/server.ts``
 * Naming convention documentation: https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/dataset
 */
export const webClientVersion: string | undefined =
  document.head.dataset.webClientVersion;
export const webClientBuildTime: string | undefined =
  document.head.dataset.webClientBuildTime;
export const analyticsEnabled: boolean =
  document.head.dataset.analyticsEnabled === "true";
export const allowGlobalSso: boolean =
  document.head.dataset.allowGlobalSso === "true";
export const autoReloadClientOnAuthFail: boolean | undefined =
  document.head.dataset.autoReloadClientOnAuthFail === "true";
export const fePerfProfiling: boolean =
  document.head.dataset.fePerfProfiling === "true";
export const customerId: string | undefined = document.head.dataset.customerId;
export const userBearerToken: string | undefined =
  document.head.dataset.userBearerToken;
export const outputsDomainName: string | undefined =
  document.head.dataset.outputsDomainName;
export const isSelfServeEnabled: boolean =
  document.head.dataset.selfServeEnabled === "true";
export const rudderstackWriteKey: string | undefined =
  document.head.dataset.rudderstackWriteKey;
export const rudderstackDataPlaneUrl: string | undefined =
  document.head.dataset.rudderstackDataPlaneUrl;
export const stytchPublicToken: string | undefined =
  document.head.dataset.stytchPublicToken;
export const isSingleTenant: boolean =
  document.head.dataset.isSingleTenant === "true";
export const zendeskKey: string | undefined = document.head.dataset.zendeskKey;
export const stripePublishableKey: string | undefined =
  document.head.dataset.stripePublishableKey;
export const launchDarklyDisabled: boolean =
  document.head.dataset.launchDarklyDisabled === "true";
export const datadogSite: string | undefined =
  document.head.dataset.datadogSite;
export const publicIps: string[] = document.head.dataset.publicIps
  ? // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
    document.head.dataset.publicIps.split(",")
  : [];
export const isMagicLinkEnabled: boolean =
  document.head.dataset.magicLinkEnabled === "true";
export const isMagicAvailable: boolean =
  document.head.dataset.magicAvailable === "true";
export const isMagicTypeaheadEnabled: boolean =
  document.head.dataset.magicTypeaheadEnabled === "true";
export const staticAssetPath: string | undefined =
  document.head.dataset.staticAssetPath;
export const isTelemetryEnabledOverride: boolean | null =
  document.head.dataset.isTelemetryEnabledOverride != null
    ? document.head.dataset.isTelemetryEnabledOverride === "true"
    : null;
export const websocketKeepAliveSeconds: string | undefined =
  document.head.dataset.websocketKeepAliveSeconds;
export const fastForwardAppSessionWindowSeconds: string | undefined =
  document.head.dataset.fastForwardAppSessionWindowSeconds;
export const baseUrl: string | undefined = document.head.dataset.baseUrl;
export const pylonAppId: string | undefined = document.head.dataset.pylonAppId;
