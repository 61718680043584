import { EnumValues } from "@hex/common";

export const CyData = {
  // General Buttons
  HEX_BUTTON_WITH_DROP_PRIMARY_BUTTON:
    "hex-button-with-drop-down-primary-button",
  HEX_BUTTON_WITH_DROP_CHEVRON_BUTTON:
    "hex-button-with-drop-down-chevron-button",
  SAVE_BUTTON: "save-button",
  ADD_BUTTON: "add-button",

  // Dropzone
  DROPZONE_BOX: "dropzone-box",
  DROPZONE_UPLOAD_BUTTON: "dropzone-upload-button",
  DROPZONE_FILE_INPUT: "dropzone-file-input",

  // Login page
  USERNAME: "username",
  PASSWORD: "password",
  LOGIN: "login",
  LOGOUT: "logout",

  // Home page
  NEW_PROJECT: "new-project",
  NEW_PROJECT_DROPDOWN: "new-project-dropdown",
  CREATE_PROJECT: "create-project",
  CREATE_R_PROJECT: "create-r-project",
  NEW_COMPONENT: "new-component",
  NEW_COMPONENT_DROPDOWN: "new-component-dropdown",
  CREATE_COMPONENT: "create-component",
  CREATE_R_COMPONENT: "create-r-component",
  SELECT_PROJECT_LANGUAGE: "select-project-language",
  UPLOAD_IPYNB: "upload-ipynb",
  IMPORT_IPYNB: "import-ipynb",
  ORG_NAME_DROPDOWN: "org-name-dropdown",
  HOME_SETTINGS_TAB: "home-settings-tab",
  IMPORT_PROJECT: "import-project",
  NAV_USER_MENU_NEW: "nav-user-menu-new",
  NEW_EXPLORATION: "new-exploration",

  // Home page sidebar tabs
  HOME_SIDEBAR_TAB_ALL_PROJECTS: "home-sidebar-tab-all",
  HOME_SIDEBAR_TAB_COLLECTIONS: "home-sidebar-tab-collections",
  HOME_SIDEBAR_TAB_COMPONENTS: "home-sidebar-tab-components",
  HOME_SIDEBAR_TAB_HOME: "home-sidebar-tab-home",
  HOME_SIDEBAR_TAB_LIBRARY: "home-sidebar-tab-library",
  HOME_SIDEBAR_TAB_LEARN: "home-sidebar-tab-learn",
  HOME_SIDEBAR_TAB_ARCHIVE: "home-sidebar-tab-archive",
  HOME_SIDEBAR_TAB_TRASH: "home-sidebar-tab-trash",
  HOME_SIDEBAR_TAB_DATA: "home-sidebar-tab-data",
  HOME_SIDEBAR_TAB_EXPLORATIONS: "home-sidebar-tab-explorations",

  // Projects home V2
  HOME_TAB_SELECT: "home-tab-view-select",
  HOME_TAB_SELECT_OPTION_ALL: "home-tab-view-select-option-all",
  HOME_TAB_SELECT_OPTION_SHARED_WITH_YOU:
    "home-tab-view-select-option-shared-with-you",
  HOME_TAB_SELECT_OPTION_SHARED_WITH_ORG:
    "home-tab-view-select-option-shared-with-org",
  HOME_TAB_SELECT_OPTION_CREATED_BY_YOU:
    "home-tab-view-select-option-created-by-you",
  HOME_TAB_DISPAY_TOGGLE_LIST_VIEW: "display-toggle-list-view",
  HOME_TAB_DISPAY_TOGGLE_GRID_VIEW: "display-toggle-grid-view",

  // Projects home tab
  HEX_LIST: "hex-list",
  HEX_ROW: "hex-row",
  HOME_TAB_SHARED_WITH_YOU: "home-tab-shared-with-you",

  // Admin page
  ADMIN_UPGRADE_PLAN_TAB: "admin-upgrade-plan-tab",
  ADMIN_BILLING_PORTAL_TAB: "admin-billing-portal-tab",
  ADMIN_BILLING_TAB: "admin-billing-tab",
  ADMIN_MANAGE_PLAN_TAB: "admin-manage-plan-tab",

  // Upgrade plan page
  UPGRADE_PLAN_TEAM_CHECKOUT: "upgrade-plan-team-checkout",
  UPGRADE_PLAN_TEAM_INDICATOR: "upgrade-plan-team-indicator",
  UPGRADE_PLAN_TEAM_TOTAL_PRICE: "upgrade-plan-team-total-price",
  UPGRADE_PLAN_COMMUNITY_INDICATOR: "upgrade-plan-community-indicator",
  UPGRADE_PLAN_TRIAL_COUNTDOWN: "upgrade-plan-trial-countdown",

  // Manage plan page
  COMMUNITY_CHECKOUT: "community-checkout",
  PROFESSIONAL_CHECKOUT: "professional-checkout",
  TEAM_CHECKOUT: "team-checkout",

  // Checkout page
  CHECKOUT_SUBSCRIPTION_TIER: "subscription-tier",
  CHECKOUT_NUM_PAID_SEATS: "checkout-num-paid-seats",
  CHECKOUT_TOTAL_AMOUNT: "checkout-total-amount",
  CHECKOUT_SUBSCRIBE: "checkout-subscribe",
  CHECKOUT_PAYMENT_ELEMENT: "stripe-payment-element",

  CHECKOUT_SUCCESS: "checkout-success-dialog",
  CHECKOUT_SUCCESS_RETURN_TO_BILLING: "checkout-success-return-to-billing",
  CHECKOUT_DOWNGRADE_COMMUNITY_CONFIRMATION:
    "checkout-community-downgrade-confirmation",

  // Billing Page
  BILLING_TIER: "billing-tier",

  // Input configuration
  INPUT_CONTAINER: "input-container",
  INPUT_STRING_CONFIG: "input-string-configuration",

  // Outline
  OUTLINE_CONTAINER: "outline-container",
  OUTLINE_LIST: "outline-list",
  EDITABLE_LABEL: "editable-label",

  // Logic layout
  LOGIC_CONTAINER: "logic-container",
  SIDEBAR_CONTAINER: "sidebar-container",

  // Shared layout
  // Learn Sidebar
  TEMPLATE_SIDEBAR: "template-sidebar",

  // Add cells
  ADD_BUTTON_INPUT: "add-button-input",
  ADD_CHART_CELL: "add-chart-cell",
  ADD_CHECKBOX_INPUT: "add-checkbox-input",
  ADD_CODE_CELL: "add-code-cell",
  ADD_DATE_INPUT: "add-date-input",
  ADD_DISPLAY_TABLE: "add-display-table",
  ADD_DROPDOWN_INPUT: "add-dropdown-input",
  ADD_MARKDOWN_CELL: "add-markdown-cell",
  ADD_MULTISELECT_INPUT: "add-multiselect-input",
  ADD_NUMBER_INPUT: "add-number-input",
  ADD_SLIDER_INPUT: "add-slider-input",
  ADD_TABLE_INPUT: "add-table-input",
  ADD_TEXT_CELL: "add-text-cell",
  ADD_TEXT_INPUT: "add-text-input",
  ADD_SQL_CELL: "add-sql-cell",
  ADD_SINGLE_VALUE_CELL: "add-single-value-cell",
  ADD_COMPONENT_IMPORT_CELL: "add-component-import-cell",
  ADD_FILE_INPUT: "add-file-input",

  // Add cell bar
  ADD_INPUT_CATEGORY: "add-input-category",
  ADD_TEXT_CATEGORY: "add-text-category",
  ADD_CELL_MORE_BUTTON: "add-cell-more-button",
  OPEN_ADD_CELL_MENU: "open-add-cell-menu",
  ADD_CELL_BUTTON_CONTENTS: "add-cell-button-contents",
  ADD_CELL_MORE_MENU_ITEM: "add-cell-more-menu",
  ADD_CELL_MENU: "add-cell-menu",
  CLOSE_ADD_CELL_MENU: "close-add-cell-menu",

  // Magic
  MAGIC_IN_CELL_BUTTON: "magic-in-cell-button",
  MAGIC_ACCEPT_POPOVER: "magic-accept-popover",
  MAGIC_KEEP: "magic-keep",
  MAGIC_REJECT: "magic-reject",
  MAGIC_CLOSE_PROMPT_BAR: "magic-close-prompt-bar",
  MAGIC_CANCEL: "magic-cancel",
  MAGIC_CONTINUE: "magic-continue",
  MAGIC_PAUSE: "magic-pause",
  MAGIC_NAME_PROJECT: "magic-name-project",
  MAGIC_LOADING_SPINNER: "magic-loading-spinner",
  MAGIC_DESCRIBE_PROJECT: "magic-describe-project",
  MAGIC_NAME_CELL: "magic-name-cell",
  MAGIC_ACTION_BAR_BUTTON: "magic-action-bar-button",

  // Notebook view cells
  CELL_SOURCE: "cell-source",
  CELL_OUTPUT: "cell-output",
  CELL_HEADER: "cell-header",

  // Metric cells
  METRIC_CELL_VALUE: "metric-cell-value",
  METRIC_CELL_TITLE: "metric-cell-title",
  TYPE_AHEAD_SELECT: "type-ahead-select",
  METRIC_CELL_SHOW_COMPARISON_SWITCH: "metric-cell-show-comparison-switch",
  METRIC_CELL_COMPARISON_TYPE_DROPDOWN: "metric-cell-comparison-type-dropdown",
  METRIC_CELL_COMPARISON_VALUE: "metric-cell-comparison-value",
  METRIC_CELL_COLUMN_VALUE: "metric-cell-column-value",

  // Cells
  CELL: "cell",
  APP_OUTLINE_CELL: "app-outline-cell",
  RUN_CELL_BUTTON: "run-cell-button",
  CONFIGURE_CELL_BUTTON: "configure-cell-button",
  EXPAND_BUTTON: "expand-button",
  OUTLINE_HEADER: "outline-header",
  TOGGLE_CAST_DECIMALS: "cast-decimals",
  CELL_MORE_ACTIONS: "cell-more-actions",
  DUPLICATE_AS_EJECTED: "duplicate-as-ejected",
  QUERY_CELL_CONFIG: "query-cell-config",
  CELL_FOOTER: "cell-footer",
  DELETE_CELL: "delete-cell",

  // SQL
  SELECT_SQL_SOURCE: "select-sql-source",
  SQL_CELL_SETTINGS: "sql-cell-settings",
  SQL_CELL_PREVIEW_OUTPUT: "sql-cell-preview-output",
  SQL_CELL_DISPLAY_OUTPUT: "sql-cell-display-output",
  // This actually tracks clicks of "view complied sql", which used to be tied to the sql run stats UI
  SQL_CELL_RUN_STATS: "sql-cell-run-stats",

  // Block
  BLOCK_CELL_CHART_OUTPUT: "block-cell-chart-output",

  // Display Table
  DISPLAY_TABLE_PAGINATION: "display-table-pagination",
  DISPLAY_TABLE_FIRST_PAGE: "display-table-first-page",
  DISPLAY_TABLE_PREV_PAGE: "display-table-prev-page",
  DISPLAY_TABLE_NEXT_PAGE: "display-table-next-page",
  DISPLAY_TABLE_LAST_PAGE: "display-table-last-page",
  DISPLAY_TABLE_ADD_CALC: "display-table-add-calc",
  DISPLAY_TABLE_CALC_RENAME: "display-table-calc-rename",

  // Explore / Explore cell
  X_AXIS_DROPZONE: "x-axis-dropzone",
  Y_AXIS_DROPZONE: "y-axis-dropzone",
  BOOKMARK_EXPLORE_BUTTON: "bookmark-explore-button",
  EXPLORE_CALC_ADD: "explore-calc-add",
  EXPAND_DETAILS_BUTTON: "expand-details-button",
  COLLAPSE_DETAILS_BUTTON: "collapse-details-button",

  // Inputs
  CHECKBOX_INPUT: "checkbox-input",
  DATETIME_INPUT_GROUP: "datetime-input-group",
  INPUT_DROPDOWN_BUTTON: "input-dropdown-button",
  MULTI_SELECT_INPUT_DIV: "multi-select-input-div",
  NUMERIC_INPUT: "numeric-input",
  SLIDER_INPUT: "slider-input",
  TABLE_INPUT_GROUP: "table-input-group",

  // Notebook view controls
  RUN_ALL_CELLS_BUTTON_GROUP: "run-all-cells",
  RESTART_AND_RUN_ALL: "restart-and-run-all",
  CLEAR_OUTPUTS: "clear-outputs",
  RUNNING_SPINNER: "running-spinner",
  ADD_ENVIRONMENT_VARIABLE: "add-environment-variable",
  ADD_HEX_USER_ATTRIBUTE: "add-hex-user-attribute",
  CELL_RUN_MODE_DROPDOWN_BUTTON: "cell-run-mode-dropdown-button",
  CELL_RUN_MODE_DROPDOWN: "cell-run-mode-dropdown",
  ERROR_BAR: "error-bar",
  KERNEL_STATUS_TEXT: "kernel-status-text",
  HELP_AND_SUPPORT: "help-and-support",
  CODE_PILL: "code-pill",

  // Project Sharing
  ALLOW_DUPLICATION_TOGGLE: "allow-duplication-toggle",
  PUBLIC_ROLE_DROPDOWN: "public-role-dropdown",
  SHARE_TO_WEB_TOGGLE: "share-to-web-toggle",

  // Navbar
  HEX_LOGIC_TITLE: "hex-project-title",
  HEX_TITLE: "hex-title",
  HEX_TITLE_INPUT: "hex-title-input",
  HEX_TITLE_BUTTON: "hex-title-button",
  DELETE_HEX: "delete-hex",
  HEX_LOGIC: "hex-logic",
  HEX_UI: "hex-ui",
  PUBLISH: "publish",
  SHARE: "share",
  HEX_LOGO: "hex-logo",
  HEX_NAV_POPOVER: "hex-nav-popover",
  RUN_APP: "run",
  TO_PUBLISHED_VERSION: "to-published-version",
  HEX_SPINNER: "hex-spinner",
  HEX_LOADING_BAR: "hex-loading-bar",

  // App Layout
  STORY_MODE: "story-mode",
  CANVAS_MODE: "canvas-mode",
  ADD_CELL_TO_LAYOUT: "add-cell-to-layout",
  REMOVE_CELL_FROM_LAYOUT: "remove-cell-from-layout",
  TEXT_INPUT: "text-input",
  STORY_CONTAINER: "story-container",
  MARKDOWN_CONTAINER: "markdown-container",
  SCREENSHOT_TARGET: "screenshot-target",
  DUPLICATE_PROJECT: "duplicate-project",
  EXPORT_AS: "export_as",
  EXPORT_AS_YAML: "export_as_yaml",
  GET_A_COPY: "get-a-copy",
  APP_CELL_LAYOUT_ELEMENT: "app-cell-layout-element",
  APP_SETTINGS_SIDEBAR_HEADING: "app-settings-sidebar-heading",
  APP_OUTLINE_CELL_DRAG_HANDLE: "app-outline-cell-drag-handle",

  // Publish Dialog
  PUBLISH_DIALOG: "publish-dialog",
  VERSION_NAME: "version-name",
  PUBLISH_VERSION: "publish-version",
  CANCEL_PUBLISH: "cancel-publish",
  PUBLISH_MENU: "publish-menu",
  PUBLISHED_TOAST: "published-toast",

  // Share Dialog
  SHARE_CONTAINER: "share-container",
  SHARE_INPUT: "share-input",
  SHARE_ROLE: "share-role",
  ADD_SHARE: "add-share",
  ROLE_PREFIX: "role-item-",

  // App Navbar
  SNAPSHOTS: "snapshots",

  // Snapshots
  ADD_SNAPSHOT: "add-snapshot",
  SNAPSHOT_CALLOUT: "snapshot-callout",

  // User menu
  USER_MENU: "user-menu",
  SETTINGS: "settings",
  PLATFORM_ADMINISTRATION: "platform-administration",
  PA_MENU_TARGET: "pa-menu-target",

  // Admin page
  USERS_TAB: "users-tab",
  ADD_USER_DIALOG_BUTTON: "add-user-dialog-button",
  USER_EMAIL_INPUT: "user-email-input",
  ADD_USER_BUTTON: "add-user-button",
  DATA_SOURCES_TAB: "data-sources-tab",
  SECRETS_TAB: "secrets-tab",
  DATA_CONNECTION_PANEL: "data-connection-panel",
  SECRET_CONNECTION_PANEL: "secret-connection-panel",
  DATA_CONNECTION_NUM_PROJECTS: "data-connection-num-projects",
  SECRET_NUM_PROJECTS: "secret-num-projects",
  PROJECT_DIALOG: "project-dialog",
  SCHEDULED_RUNS_TAB: "scheduled-runs-tab",

  // General
  TABLE_ROW: "table-row",
  TABLE_CELL: "table-cell",
  ROW_ITEM: "row-item",
  TABLE_CELL_INPUT_DIV: "table-cell-input-div",
  COMMENT_BADGE: "comment-badge",
  COMMENT_EDITOR: "comment-editor",
  ADD_COMMENT_BUTTON: "add-comment-button",
  COMMENT_BODY: "comment-body",
  CONFIRM_BUTTON: "confirm-button",
  UPLOAD_FILE: "upload-file",
  EMAIL_TAG_INPUT: "email-input",
  HEX_MENU_WRAPPER: "hex-menu-wrapper",

  // Tabs
  VARIABLES_TAB: "variables-tab",
  DATA_CONNECTION_TAB: "data-connection-tab",
  COMMENTS_TAB: "comments-tab",
  OUTLINE_TAB: "outline-tab",
  SCHEDULED_RUNS_SIDEBAR_TAB: "scheduled-runs-sidebar-tab",
  // Variables tab
  VARIABLES_LIST: "variables-list",

  // Data Connection
  ADD_DATA_CONNECTION: "add-data-connection",
  NEW_DATA_CONNECTION: "new-data-connection",
  CREATE_NEW_DATA_CONNECTION: "create-new-data-connection",
  CREATE_NEW_WORKSPACE_CONNECTION: "create-new-workspace-connection",
  SUBMIT_NEW_DATA_CONNECTION: "submit-new-data-connection",
  TOGGLE_SSH_DATA_CONNECTION: "toggle-ssh-data-connection",
  DATA_CONNECTION_ROW: "data-connection-row",
  DATA_CONNECTION_ROW_MENU: "data-connection-row-menu",
  EDIT_DATA_CONNECTION: "edit-data-connection",
  DELETE_DATA_CONNECTION: "delete-data-connection",
  IMPORT_ORG_CONNECTION: "import-org-connection",
  DATA_CONNECTION_SELECT_CONTAINER: "data-connection-select-container",
  DATA_CONNECTIONS_LABEL: "data-connections-label",
  DATA_CONNECTION_LAST_REFRESHED: "data-connection-last-refreshed",
  ADD_DATA_CONNECTION_DIALOG: "add-data-connection-dialog",

  // Secrets
  CREATE_NEW_WORKSPACE_SECRET: "create-new-workspace-secret",
  SUBMIT_NEW_SECRET: "submit-new-secret",
  IMPORT_ORG_SECRET: "import-org-secret",
  SECRET_DIALOG: "secret-dialog",
  SECRET_ROW: "secret-row",
  SECRET_CHECKBOX: "secret-checkbox",
  CONFIRM_SECRET_IMPORT: "confirm-secret-import",

  // Versions + History
  VERSION_TAB: "version-tab",
  SAVE_NEW_VERSION: "save-new-version",
  VERSION_BUTTON_GROUP: "version-button-group",
  IMPORT_VERSION_BUTTON: "import-version-button",
  IMPORT_RESTORE_VERSION: "import-restore-version",

  // Scope Items
  SCOPE_SELECT_BUTTON: "scope-select",

  // Snowflake OAuth Connection
  ADD_SNOWFLAKE_OAUTH_CONNECTION: "add-snowflake-oauth-connection",
  SNOWFLAKE_CONNECTION_NAME: "snowflake-connection-name",
  SNOWFLAKE_ACCOUNT_NAME: "snowflake-account-name",
  SNOWFLAKE_CLIENT_ID: "snowflake-client-id",
  SNOWFLAKE_CLIENT_SECRET: "snowflake-client-secret",
  SNOWFLAKE_ADD_CONNECTION: "snowflake-add-connection",

  // BigQuery OAuth Connection
  ADD_BIGQUERY_OAUTH_CONNECTION: "add-bigquery-oauth-connection",
  BIGQUERY_CONNECTION_NAME: "bigquery-connection-name",
  BIGQUERY_PROJECT_ID: "bigquery-project-id",
  BIGQUERY_CLIENT_ID: "bigquery-client-id",
  BIGQUERY_CLIENT_SECRET: "bigquery-client-secret",
  BIGQUERY_UPDATE_CONNECTION: "bigquery-update-connection",

  // Databricks OAuth Connection
  ADD_DATABRICKS_OAUTH_CONNECTION: "add-databricks-oauth-connection",
  DATABRICKS_CONNECTION_NAME: "databricks-connection-name",
  DATABRICKS_HOST: "databricks-host",
  DATABRICKS_CLIENT_ID: "databricks-client-id",
  DATABRICKS_CLIENT_SECRET: "databricks-client-secret",
  DATABRICKS_UPDATE_CONNECTION: "databricks-update-connection",

  // Signup
  SIGNUP_NAME: "signup-name",
  SIGNUP_EMAIL: "signup-email",
  SIGNUP_SUBMIT: "signup-submit",

  // Workspace Picker
  WORKSPACE_PICKER_ROW: "workspace-picker-row",

  // Welcome
  WELCOME_NAME: "welcome-name",
  WELCOME_DOMAIN_TOGGLE: "welcome-domain-toggle",
  WELCOME_INVITE_USERS: "welcome-invite-user",
  WELCOME_SUBMIT: "welcome-submit",
  WELCOME_SUBMIT_QUESTIONNAIRE: "welcome-subit-questionnaire",

  // USER INTENT QUESTIONNAIRE
  WORKSPACE_INTENT: "workspace_intent",
  COLLABORATION_INTENT: "collaboration-intent",
  LANGUAGE_SELECTION: "language-selection",

  // Onboarding
  LANGUAGE_SELECTION_SUBMIT: "language-selection-submit",
  TUTORIAL_PROJECT_CARD: "tutorial-project",
  CREATE_PROJECT_ONBOARDING_STEP: "create-project-onboarding-step",

  // Tours
  TOUR_STEP: "tour-step",

  // Components
  UPDATE_AVAILABLE: "update-available",
  UPDATE_COMPONENT: "update-component",
  COMPONENT_IMPORT_DIALOG: "component-import-dialog",

  // Announcements
  ANNOUNCEMENT_DISMISS: "announcement-dismiss",

  // Scheduled Runs
  ADD_SCHEDULED_RUN: "add-scheduled-run",
  EDIT_SCHEDULE_DIALOG: "edit-schedule-dialog",
  ADD_SCHEDULED_RUN_NOTIFICATION: "add-scheduled-run-notification",
  TEST_SCHEDULED_RUN: "test-scheduled-run",
  TEST_SCHEDULED_RUN_DROPDOWN_BUTTON: "test-scheduled-run-dropdown-button",
  EDIT_SCHEDULE_NOTIFICATION: "edit-schedule-notification",
  SCHEDULE_USERS_OR_GROUPS: "schedule-users-or-groups",
  SCHEDULE_INCLUDE_SCREENSHOT: "schedule-include-screenshot",

  // New Project Checklist
  NEW_PROJECT_CHECKLIST_QUERY_DATA: "new-project-checklist-query-data",
  NEW_PROJECT_CHECKLIST_BUILD_ANALYSIS: "new-project-checklist-build-analysis",
  NEW_PROJECT_CHECKLIST_PUBLISH: "new-project-checklist-publish",
  NEW_PROJECT_CHECKLIST_SHARE: "new-project-checklist-share",
  NEW_PROJECT_CHECKLIST_COLLAPSE: "new-project-checklist-collapse",
  NEW_PROJECT_CHECKLIST_EXPAND: "new-project-checklist-expand",
  NEW_PROJECT_CHECKLIST_DISMISS: "new-project-checklist-dismiss",

  // Data Browser
  DATA_BROWSER_HEADER_DATA: "data-browser-header-data",
  DATA_BROWSER_HEADER_TOGGLE: "data-browser-header-toggle",
  DATA_BROWSER_ROW_TOGGLE: "data-browser-row-toggle",
  DATA_BROWSER_INCLUDE_BUTTON: "data-browser-include-button",
  DATA_BROWSER_EXCLUDE_BUTTON: "data-browser-exclude-button",
  DATA_BROWSER_CONFIRM_DIALOG: "data-browser-confirm-dialog",
  DATA_BROWSER_ROW_TITLE: "data-browser-row-title",
  DATA_BROWSER_MANAGE_PROPERTIES: "data-browser-manage-properties",
  OPEN_PREVIEW: "open-preview",
};

export type CyData = EnumValues<typeof CyData>;

export const isCyDataValue = (x: string): x is CyData =>
  Object.values(CyData).includes(x);

/**
 * Returns a CSS selector matching an element/tree of the provided CyData values.
 * Accepts variable number of keys to allow construction of more specific
 * selectors where generic or re-used components might exist.
 */
export const dataCy = (...keys: (CyData | string)[]): string =>
  keys.map((key) => `[data-cy="${key}"]`).join(" ");
